.DayView {
  border-bottom: 1px solid #E6E8F0;
  /*border-left: 1px solid #bdbdbd;*/
  /*border-right: 1px solid #bdbdbd;*/
}

.DayViewDark {
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
  /*border-left: 1px solid #bdbdbd;*/
  /*border-right: 1px solid #bdbdbd;*/
}

.DayView-first-day-of-week {
  /*border-top: 1px solid #bdbdbd;*/
}

.DayView-last-day-of-week {
  border-bottom: 0 !important;
}